<template>
    <router-view :key="$route.fullPath"/>
</template>

<script>

export default {
  name: 'Cash',
  beforeRouteEnter (to, from, next) {
    next()
  }
}
</script>

<style scoped>

</style>
